import styled from "styled-components";

const NavStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;

  /* mix-blend-mode: difference; */
  /* padding: 0%.5 0; */

  .active {
    font-family: "DIN Pro Medium";
    color: var(--lightcreme) !important;
  }

  .custom-container {
    display: flex;
    background-color: var(--green);
    justify-content: space-between;
    overflow: visible;
    padding-top: var(--size05) !important;
    padding-bottom: var(--size05) !important;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1.5rem 0;
    margin-bottom: 3rem;
    &.open {
      display: flex;
    }

    a {
      margin-bottom: 1rem;
      color: var(--lightcreme);
      font-size: var(--p-large);
    }

    a:hover {
      font-family: "DIN Pro Medium";
      color: var(--lightcreme) !important;
    }
  }

  .burger-menu {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    right: var(--size1);
    bottom: var(--size1);
    cursor: pointer;

    .line {
      width: 30px;
      height: 3px;
      background-color: var(--lightcreme);
      margin-bottom: 5px;
    }
  }

  //DESKTOP

  @media (min-width: 900px) {
    mix-blend-mode: difference;
    top: 0;
    /* background-color: var(--brown); */
    bottom: inherit;

    height: ${(props) => (props.index && props.navtop ? "100vh" : "auto")};
    justify-content: ${(props) =>
      props.index && props.navtop ? "flex-end" : ""};
    position: ${(props) =>
      props.index && props.navtop ? "absolute" : "fixed"};
    transition: all 3s ease;
    /* mix-blend-mode: hard-light; */
    .custom-container {
      padding-top: ${(props) =>
        props.index && props.navtop ? "var(--size1)" : "var(--size05)"};
      padding-bottom: ${(props) =>
        props.index && props.navtop ? "var(--size1)" : "var(--size05)"};
      transition: all 0.3s ease-in-out;
      /* width: calc(100% - var(--very-large) * 2); */
    }

    .custom-container.full-height {
      min-height: inherit;
    }

    .nav-links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0;
      a {
        margin-bottom: 0;
        color: var(--brown);
      }
    }

    .burger-menu {
      display: none;
    }
    .logo {
      padding-right: 7.5rem;
      img {
        height: 4.7rem;
      }
    }
  }

  @media (max-width: 900px) {
    .custom-container {
      align-items: ${(props) => (props.isopen ? "end" : "")};
    }
    a {
      margin-bottom: 0;
      font-size: var(--size1);
    }

    .logo {
      img {
        height: 11vw;
      }
    }

    .logo-menu-open {
      position: absolute;
      bottom: var(--size1);
    }
  }

  @media (max-width: 900px) {
    .nav-links {
      a {
        font-size: var(--very-large);
      }
    }
  }
  @media print {
    display: none;
  }
`;

export default NavStyle;

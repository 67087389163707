exports.components = {
  "component---src-components-templates-archives-subcategory-js": () => import("./../../../src/components/templates/ArchivesSubcategory.js" /* webpackChunkName: "component---src-components-templates-archives-subcategory-js" */),
  "component---src-components-templates-artist-js": () => import("./../../../src/components/templates/Artist.js" /* webpackChunkName: "component---src-components-templates-artist-js" */),
  "component---src-components-templates-object-js": () => import("./../../../src/components/templates/Object.js" /* webpackChunkName: "component---src-components-templates-object-js" */),
  "component---src-components-templates-subcategory-js": () => import("./../../../src/components/templates/Subcategory.js" /* webpackChunkName: "component---src-components-templates-subcategory-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archives-js": () => import("./../../../src/pages/archives.js" /* webpackChunkName: "component---src-pages-archives-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-catalogues-js": () => import("./../../../src/pages/catalogues.js" /* webpackChunkName: "component---src-pages-catalogues-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-form-enquiry-js": () => import("./../../../src/pages/form-enquiry.js" /* webpackChunkName: "component---src-pages-form-enquiry-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}


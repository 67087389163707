import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import FooterStyle from "../../styles/FooterStyle";
import ResponsiveGatsbyImage from "../../utils/ResponsiveGatsbyImage";
import useSendNewsletter from "../../utils/useSendNewsletter";
import useForm from "../../utils/useForms";

const Footer = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [browserType, setBrowserType] = useState("");

  const { values, handleChange, resetForm } = useForm({
    email: "",
  });

  const { messageSuccessNews, errorNews, loadingNews, submitNewsletter } =
    useSendNewsletter({
      values,
    });

  const data = useStaticQuery(graphql`
    query {
      queryAbout: sanityAbout {
        email
        logoLinksFooter {
          alt {
            en
            fr
          }
          link
          img {
            asset {
              mobileImage: gatsbyImageData(
                width: 480
                layout: CONSTRAINED
                formats: [AUTO, WEBP, AVIF]
              )
              desktopImage: gatsbyImageData(
                width: 800
                layout: CONSTRAINED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        phoneNumber {
          en
          fr
        }
        address {
          en
          fr
        }
      }
    }
  `);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isChrome =
      /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
    setBrowserType(isChrome ? "chrome" : "firefox");
  }, []);

  return (
    <FooterStyle
      className="custom-container no-show-print"
      browsertype={browserType}
    >
      <div className="box-1">
        <div className="flex gap-8 flex-col  lg:flex-row  lg:justify-between lg:items-center">
          <h5 className="uppercase whitespace-nowrap ">{t("newsletter")}</h5>
          <form
            className="relative flex items-center justify-between "
            onSubmit={submitNewsletter}
          >
            <input
              className="input-newsletter flex-grow lg:w-60  lg:w-80 min-w-80 "
              type="email"
              placeholder={t("enter-email")}
              name="email"
              value={values.email}
              onChange={handleChange}
              required
            />
            <button
              type="submit"
              className="bg-creme-100 text-green rounded-[3rem] absolute w-20  right-0.5 h-8  "
              disabled={loadingNews}
            >
              <p className="text-lg">
                {loadingNews ? t("loading") : t("join")}
              </p>
            </button>
            {messageSuccessNews && (
              <p className="absolute top-10 left-3">{messageSuccessNews}</p>
            )}
            {errorNews && <p className="absolute top-10 left-3">{errorNews}</p>}
          </form>

          <div className="flex items-center gap-6 mb-6 flex-wrap">
            {data.queryAbout.logoLinksFooter.map((logo) => (
              <a
                href={logo.link}
                target="_blank"
                rel="noreferrer"
                key={uuidv4()}
              >
                <ResponsiveGatsbyImage
                  mobileImageData={logo.img.asset.mobileImage}
                  desktopImageData={logo.img.asset.desktopImage}
                  alt={i18n.language === "fr" ? logo.alt.fr : logo.alt.en}
                />
              </a>
            ))}
          </div>
        </div>
        <div className="flex justify-between lg:items-end flex-col lg:flex-row">
          <div className="flex flex-row gap-6 mb-6 lg:mb-0 justify-between w-full lg:w-[23rem]">
            <div className="w-[50%]">
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === "fr"
                      ? data.queryAbout.address.fr
                      : data.queryAbout.address.en,
                }}
              ></p>
            </div>
            <div className="w-[50%]">
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "fr"
                        ? data.queryAbout.phoneNumber.fr
                        : data.queryAbout.phoneNumber.en,
                  }}
                ></p>
              </div>
              <div>
                <a href={`mailto:${data.queryAbout.email}`}>
                  <p> {data.queryAbout.email}</p>
                </a>
              </div>
            </div>
          </div>
          <Link to="/privacy-policy/" className="light">
            <p>
              {data.queryAbout.copyright
                ? data.queryAbout.copyright
                : "Gregg Baker Asian Art © Copyright 2024 – Privacy Policy"}
            </p>
          </Link>
        </div>
      </div>
    </FooterStyle>
  );
};

export default Footer;

import React, { useState, useContext, useEffect } from "react";
import "normalize.css";

import GlobalStyles from "./styles/GlobalStyles";
import TypographyStyles from "./styles/TypographyStyles";
import Nav from "./global-components/Navbar/Nav";
import Footer from "./global-components/Footer/Footer";
import FooterPrint from "./global-components/Footer/FooterPrint";
import { PrintContext } from "../context/printContext";
// import SEO from "./seo";
import "../components/styles/printPreview.css";

import { applyTruncateTextToSafari } from "./utils/truncateSafariText";

const Layout = ({ children, location }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function checkIfMobile() {
      return window.innerWidth <= 900;
    }

    setIsMobile(checkIfMobile());

    function handleResize() {
      setIsMobile(checkIfMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const { printMode, togglePrintMode } = useContext(PrintContext);

  return (
    <div>
      <FooterPrint />
      <Nav location={location} classGreen="greenNav" />
      <GlobalStyles />
      <TypographyStyles />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;

import React from "react";
import { FooterPrintStyle } from "../../styles/FooterStyle";
import { useTranslation } from "react-i18next";

import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import LogoPrint from "../../../assets/images/gregg-vert.jpg";
const FooterPrint = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      queryAbout: sanityAbout {
        email
        phoneNumber {
          en
          fr
        }
        address {
          en
          fr
        }
      }
    }
  `);

  return (
    <FooterPrintStyle className="custom-containerfooter-print">
      <img src={LogoPrint} alt="Logo" />

      <p>Brussels</p>
      <p
      // dangerouslySetInnerHTML={{
      //   __html:
      //     i18n.language === "fr"
      //       ? data.queryAbout.address.fr
      //       : data.queryAbout.address.en,
      // }}
      >
        www.japanesescreens.com
      </p>
      {/* <p>{data.queryAbout.phoneNumber.en},</p> */}

      <a href={`mailto:${data.queryAbout.email}`}>
        <p> {data.queryAbout.email}</p>
      </a>
    </FooterPrintStyle>
  );
};

export default FooterPrint;

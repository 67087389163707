import { useState, useEffect, useRef } from "react";

const useNavbarToggle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);

  const handleNavbarClick = (event) => {
    if (
      event.target === navbarRef.current ||
      navbarRef.current.contains(event.target)
    ) {
      toggleMenu();
    }
  };

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", () => setIsOpen(false), {
      passive: true,
    });

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", () => setIsOpen(false));
    };
  }, []);

  useEffect(() => {
    const navbar = navbarRef.current;
    if (navbar) {
      navbar.addEventListener("click", handleNavbarClick);
    }

    return () => {
      if (navbar) {
        navbar.removeEventListener("click", handleNavbarClick);
      }
    };
  }, [isOpen]);

  return { isOpen, setIsOpen, navbarRef };
};

export default useNavbarToggle;

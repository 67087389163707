import React, { useEffect } from "react";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

const ResponsiveGatsbyImage = ({
  mobileImageData,
  desktopImageData,
  alt,
  hotspot,
  className,
  onload,
  loaded,
}) => {
  useEffect(() => {
    if (loaded) {
      onload();
    }
  }, [loaded, onload]);

  if (!mobileImageData || !desktopImageData) {
    return null;
  }

  const mobileImage = getImage(mobileImageData);
  const desktopImage = getImage(desktopImageData);

  const images = withArtDirection(desktopImage, [
    {
      media: "(max-width: 479px)",
      image: mobileImage,
    },
  ]);

  // Calculate object position based on hotspot data
  const objectPosition = hotspot
    ? `${hotspot.x * 100}% ${hotspot.y * 100}%`
    : "center center";

  const imgStyles = {
    objectPosition,
    objectFit: "cover",
  };

  return (
    <GatsbyImage
      image={images}
      alt={" "}
      className={className}
      imgStyle={imgStyles}
      loading="eager"
      placeholder="sources"
    />
  );
};

export default ResponsiveGatsbyImage;

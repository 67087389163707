import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import NavStyle from "../../styles/NavStyle";
import React, { useEffect, useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  handleLinkClickUtil,
  isActiveUtil,
  localizePathUtil,
} from "../../utils/Navbar help/mobilNavUtils";
import useNavbarToggle from "../../utils/Navbar help/useNavbarToggle";
import Logo from "../../../assets/images/LOGO.svg";

const Nav = ({ location, classGreen }) => {
  const { t, i18n } = useTranslation();
  const { isOpen, setIsOpen, navbarRef } = useNavbarToggle();

  const [navTop, setNavTop] = useState(true);
  const [containerInitialTop, setContainerInitialTop] = useState(0);
  const containerRef = useRef(null);
  // Reference to the navbar

  const links = [
    { to: "/collections", textKey: "collections" },
    { to: "/artists", textKey: "artists" },
    { to: "/events", textKey: "events" },
    { to: "/catalogues", textKey: "catalogues" },
    { to: "/archives", textKey: "archives" },
    { to: "/press", textKey: "press" },
    { to: "/about", textKey: "about-us" },
    { to: "/search", textKey: "search" },
    // Add other links here
  ];
  useEffect(() => {
    // Set the initial top offset of the container once it's available
    if (containerRef.current) {
      setContainerInitialTop(containerRef.current.offsetTop);
    }
  }, []); // This runs only once after the initial render

  useEffect(() => {
    const handleScroll = () => {
      // Use containerInitialTop to determine if we're above or below it
      const isAboveInitialTop = window.scrollY < containerInitialTop;
      setNavTop(isAboveInitialTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [containerInitialTop]); // Depend on containerInitialTop so it runs after being set

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        // Calculate if we're  the top of the container

        const isTop = window.scrollY > containerRef.current.offsetTop;

        // Set state based on whether we're  the top
        // No need to toggle; set it based on the condition directly
        setNavTop(!isTop);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // navbarRef added as a dependency

  return (
    <NavStyle
      isopen={isOpen ? 1 : 0}
      ref={navbarRef}
      className={classGreen}
      index={location.pathname === "/" ? 1 : 0}
      navtop={navTop ? 1 : 0}
    >
      <div
        className="custom-container flexbox full-height no-show-print"
        ref={containerRef}
      >
        <div className={`logo ${isOpen ? "logo-menu-open" : ""}`}>
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>

        <div className={`nav-links ${isOpen ? "open" : ""}`}>
          {links.map(({ to, textKey }) => (
            <Link
              key={to}
              to={localizePathUtil(to, i18n)}
              className={isActiveUtil(to, location, i18n) ? "active" : ""}
              onClick={(event) => handleLinkClickUtil(event, isOpen, setIsOpen)}
            >
              {t(textKey)}
            </Link>
          ))}
        </div>
        <div className="burger-menu">
          <div className={`line ${isOpen ? "open" : ""}`}></div>
          <div className={`line ${isOpen ? "open" : ""}`}></div>
          <div className={`line ${isOpen ? "open" : ""}`}></div>
        </div>
      </div>
    </NavStyle>
  );
};

export default Nav;

export const toggleMenuUtil = (isOpen, setIsOpen) => setIsOpen(!isOpen);

export const handleLinkClickUtil = (event, isOpen, setIsOpen) => {
  event.stopPropagation(); // Prevent triggering toggleMenu when clicking a link
  if (isOpen) {
    setIsOpen(false);
  }
};

// Toggle menu when clicking on the navbar, but not on links or outside

export const isActiveUtil = (path, location, i18n) => {
  const normalizedPath = path.endsWith("/") ? path : `${path}/`;
  const prefixedPath =
    i18n.language === "fr" ? `/fr${normalizedPath}` : normalizedPath;
  const normalizedLocation = location.pathname.endsWith("/")
    ? location.pathname
    : `${location.pathname}/`;
  return normalizedLocation === prefixedPath;
};

export const localizePathUtil = (path, i18n) =>
  i18n.language === "fr" ? `/fr${path}` : path;

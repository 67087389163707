import React from "react";
import Layout from "./src/components/Layout";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { PrintProvider } from "./src/context/printContext";
import "./src/styles/global.css";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export const wrapRootElement = ({ element }) => {
  return (
    <PrintProvider>
      <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
    </PrintProvider>
  );
};

import React, { createContext, useState, useEffect, useRef } from "react";

// Create the context object with a default value
export const PrintContext = createContext({
  printMode: false,
  togglePrintMode: () => {},
});

export const PrintProvider = ({ children }) => {
  const [printMode, setPrintMode] = useState(false);
  const debounceTimeoutRef = useRef(null);
  useEffect(() => {
    const mediaQueryList = window.matchMedia("print");

    const handleMediaChange = (mql) => {
      // Clear existing timeout to debounce the print mode setting
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        if (mql.matches) {
          console.log("Entering print mode");
          setPrintMode(true);
        } else {
          console.log("Exiting print mode");
          setPrintMode(false);
        }
      }, 100); // Debounce time of 100ms
    };

    mediaQueryList.addListener(handleMediaChange);

    return () => {
      mediaQueryList.removeListener(handleMediaChange);
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  const togglePrintMode = () => {
    setPrintMode(!printMode);
  };

  return (
    <PrintContext.Provider value={{ printMode, togglePrintMode }}>
      {children}
    </PrintContext.Provider>
  );
};

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
:root {
  --black: #070024;
  --brown: #261C1C;
  --creme:  #D3CCC0;
  --transparentCreme: #FFFBEC1C;
  --green: #616730;
  --lightcreme: #F3EFE1;
  --size05: 0.4rem;
  --size07: 0.85rem;
  --size1: 1rem;
  --size2: 1.5rem;
  --size3: 3rem;
  --size4: 8.5rem;
  --h5size: 1.1rem;
  --sizeH4: 20px;
  --very-large: 1rem;
  --title-box-h3: 20px;
  --big-bold-title: 17px;
  --p-small:  11px;
  --p-large: 0.8rem;
  --line-height-p-small: 0.9rem;
  --line-height-p-large: 1.1rem;
  --big-legend: 12px;
}


//RESET

body {
  color: var(--black);
  scrollbar-color: #cfcfcf white;
  scrollbar-width: thin;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}






//SCROLL BAR

body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: var(--green);
}

::-webkit-scrollbar-thumb {
  background: var(--green);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px;
}

::-webkit-scrollbar-corner {
  background: var(--brown);
}

.scroll-horizontal-box {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-horizontal-box::-webkit-scrollbar {
  display: none;
}







.custom-container {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding:  5.5rem var( --very-large) ;
  width: 100%;
    /* width: calc(100% - (var( --very-large) * 2)) ; */
}











//UTILITY CLASS
.flexbox {
  display: flex;
  
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.columns-grid {
  .title-box {
    margin-bottom: var(--size05);
    h3 {
      cursor: inherit;
    }
  }
}

.columns-grid {
  /* column-count: 0; */
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(30%,1fr));
  grid-auto-flow: row dense;
  column-gap: var(--size2);
  width: 100%;
}

.columns-grid > div {
  break-inside: avoid;
  margin-bottom: var(--size1);
  width: 100%;
}



.hide {
  display: none !important;
}

.opacity-0{
  opacity: 0;
}


.text-justify {
  text-align: justify;
}
.top-margin-big {
  padding-top: 4rem !important;
}


.top-margin-2 {
  margin-top: var(--size2);
}

.img-contain img{

   object-fit: contain !important;
  

   
}



//BTN 

.btn {
  padding: 0.2rem 0.4rem;
  
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  p {
    padding: 0 !important;
  }
  
  border-radius: 1rem;
}

.btn-creme {
  background-color: var(--lightcreme);
  color: var(--green);
  border: 1px solid var(--green);
}

.btn-green {
  background-color: var(--green);
  color: var(--lightcreme);
  border: 1px solid var(--green);
}





//LITTLE ROUND

.little-round {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.little-round.pleft {
  margin-right: 0;
  margin-left: var(--size05);
}

.und10 {
  background-color: #F8C23966;
}

.und50 {
  background-color: #6BD4EB66;
}

.und100 {
  background-color: #F93C3066;
}

.ov100 {
  background-color: #00000066;
}






// FOR DESKTOP

@media (min-width: 900px) {
  
  :root {
    --size05: 0.685rem;
    --size07: 0.85rem;
    --size1: 1.25rem;
    --size2: 2rem;
    --size3: 4rem;
    --size4: 8.5rem;
    --h5size: 30px;
    --line-height-h5: 38.64px;
    --sizeH4: 30px;
    --very-large: 6rem;
    --title-box-h3: 30px;
    --big-bold-title: 40px;
 
    --line-height-p-small: 1.3rem;
    --line-height-p-large: 1.7rem;
    --big-legend:  20px;
    --p-small: 11px;
    --p-large: 15px;
  }
  
  /* .logo {
    mix-blend-mode: exclusion;
  } */
  .greenNav {
    mix-blend-mode: normal !important;
    
    .logo {
      mix-blend-mode:inherit;
    }
    .custom-container {
      background-color: var(--green) !important;
      color: var(--lightcreme) !important;
      a {
        color: var(--lightcreme) !important;
      }
    }
  }
  
  
  
  .greenNav.big {
    padding-top: var(--size2) !important;
    padding-bottom: var(--size2) !important ;
  }
  
  
  
  

  
  .top-margin-big {
    padding-top: 7.5rem !important;
  }
  
  .link-image {
    transition: transform 0.5s ease;
    display: inline-block;
    perspective: 1000px; /* Adds depth to the 3D effect */
    height: 100%;
  }
  
  .link-image img {
    transition: transform 0.5s ease;
    transform: translate3d(0, 0, 0); /* Initial state */
  }
  
  .link-image:hover img {
    transform: rotate3d(2, 2, 0, 5deg) translate3d(0, 0, 10px); /* 3D move on hover */
  }
  
  
  .cutsom-container.full-height {
    min-height: 84vh;
    padding: 7.5rem var( --very-large) 4vh var( --very-large);
  }
  
  .btn {
    padding: 0.3rem 0.5rem;
  }
  
  .little-round {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: var(--size05);
  }

  .columns-grid {
 
 
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  
  .flexbox{
    width: 100%;
  }
}


@media (min-width: 1200px) {

  :root {
   
    --size3: 5rem;
   
    --very-large: 10rem;
    --p-small: 15px;
    --p-large: 20px;
    
  }


}


@media (max-width: 1200px) {
  .slider {
    .slick-prev, .slick-next {
      display: none !important;
    }
  }
  .slick-prev, .slick-next, .slick-next::before, .slick-prev::before {
    display: none !important;
  }  
}
`;

export default GlobalStyles;

import { createGlobalStyle } from "styled-components";

const TypographyStyles = createGlobalStyle`

@font-face {
  font-family: 'DIN Pro Light';
  src: local('DIN Pro Light'), local('DIN-Pro-Light'),
      url("/fonts/fontdinpro/DINPro-Light.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro'), local('DIN-Pro'),
      url("/fonts/fontdinpro/DINPro.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Medium';
  src: local('DIN Pro Medium'), local('DIN-Pro-Medium'),
      url("/fonts/fontdinpro/DINPro-Medium.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Bold';
  src: local('DIN Pro Bold'), local('DIN-Pro-Bold'),
      url("/fonts/fontdinpro/DINPro-Bold.woff") format('woff');
  font-weight: 500; 
  font-style: normal;
} 


  body {
    font-variant-ligatures: none;
    font-family: 'DIN Pro Light', Arial, Helvetica, sans-serif;
    font-weight: 400;
  }

  p, h1, h2, h3, h4, h5, ul {
    padding: 0;
    margin: 0;
    font-weight: normal;
  }

  a {
    font-family: 'DIN Pro Light', Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: var(--brown);
  }

  /* h3 {
    font-size: var(--title-box-h3);
  } */

  h4 {
    font-size: var(--sizeH4);
  }

  h5 {
    font-family: 'DIN Pro';
    font-size: var(--h5size);
    padding-bottom: var(--size1);
  }

  p {
    font-size: var(--p-small);
    line-height: var(--line-height-p-small);
  }

  .large p {
    font-size: var(--p-large);
    line-height: var(--line-height-p-large);
  }

  .title-box.small-title-box {
    text-transform: capitalize;
    h3 {
      font-weight: initial;
      font-family: 'DIN Pro Light';
    }
    a {
      font-family: 'DIN Pro';
    }
  }

  .title-box.small-title-box:hover {
    color: inherit !important;
    cursor: auto !important;
  }

  .big-bold-title {
    font-size: var( --big-bold-title);
    font-family:'DIN Pro Medium';
    font-weight: 500;
  }

  .date-title {
    font-size: var(--big-legend);
    margin-bottom: var(--size1);
  }

  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: var(--size1);
  
    svg {
      font-size: 1.4rem;
    }

    /* h3 {
      font-size: var(--title-box-h3);
    } */
  }

  // ELLIPSIS AND UTILITIES

  .big-ellipsis,
  .small-ellipsis {
    text-align: justify; 
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: pre-wrap; 
    position: relative;
    
    p:not(:first-child) {
      display: none;
    }
  }

  .big-ellipsis::after,
  .small-ellipsis::after {
    content: '';
  }

  .big-ellipsis {
    -webkit-line-clamp: 3;
  }

  .small-ellipsis {
    -webkit-line-clamp: 2;
  }

  .bold {
    font-family: 'DIN Pro' !important;
    font-weight: 500;
  }

  @media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) {

  }}

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .big-ellipsis::after,
      .small-ellipsis::after {
        content: '...';
        position: absolute;
        bottom: 0;
        right: 0;
        padding-left: 5px;
      }
    }
  }

  @media (min-width: 900px) {
    .big-bold-title {
      line-height: inherit;
      font-family: 'DIN Pro Medium';
      padding-bottom: var(--size1);
    }

    .date-title {
      margin-bottom: var(--size1);
    }

    .title-box {
      margin-bottom: var(--size2);
      padding-bottom: 0.5rem;
      transition: all 0.2s;
    }

    .title {
      transition: all 0.2s;
    }

  
    .big-ellipsis {
      -webkit-line-clamp: 5;
    }

    .small-ellipsis {
      -webkit-line-clamp: 3;
    }

    h5 {
      font-family: 'DIN Pro';
    }
  }

  @media (max-width: 900px) {

  }

`;

export default TypographyStyles;

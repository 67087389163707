import styled from "styled-components";

const FooterStyle = styled.div`
  /* Add your styling here */
  padding-top: var(--size1) !important;
  padding-bottom: var(--size2) !important;

  background-color: var(--green);
  color: var(--lightcreme);

  p {
    font-family: "DIN Pro";
  }

  a {
    color: var(--lightcreme);
  }
  h4 {
    text-transform: uppercase;
  }

  .flexbox {
    gap: var(--size1);
    justify-content: space-between;
    width: inherit;
  }

  .box-1 {
    width: 100%;
    .flexbox {
      gap: var(--size3);
    }
  }

  .logo-box {
    gap: var(--size05) !important;
  }

  .light p {
    font-family: "DIN Pro Light";
  }

  .green {
    mix-blend-mode: normal;
    background-color: var(--green);
  }

  .flex-end {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: end;
  }

  .gatsby-image-wrapper {
    height: fit-content;
    width: 50px;
  }
  .input-newsletter {
    padding: 0.3rem 0.5rem;
    width: calc(100% - var(--size1)) !important;
    border-radius: 3rem;
    border: 1px solid var(--lightcreme);
    background-color: var(--green);
    color: var(--lightcreme);
    /* height: fit-content; */
  }

  .input-newsletter::placeholder {
    color: var(--lightcreme); /* Change 'red' to whatever color you prefer */
  }

  .input-box {
    position: relative;
    flex-grow: 1;
    width: 100%;
    .btn {
      border-radius: 3rem;
      position: absolute;
      width: 5rem;
      right: 1.3rem !important;
      padding: 4px;
      top: 1px !important;
    }
  }

  .input-box .btn {
    top: ${(props) => (props.browsertype === "chrome" ? "0px" : "1px")};
    // Add other conditional styles based on the browserType prop
  }

  h5 {
    font-size: var(--p-large);
    padding-bottom: 0;
  }
  @media screen and (max-width: 900px) {
    padding-bottom: var(--size4) !important;
    padding-top: var(--size3) !important;
    .box-1,
    .box-2 {
      width: 100%;
      .flexbox > * {
        width: calc(100%);
      }
    }
    .box-1 {
      .flexbox {
        gap: var(--size2);
      }
    }

    .box-2 {
      padding-top: var(--size2);
    }
    .input-box {
      .btn {
        right: 1.1rem !important;
        top: 0px;
        padding: 3px;
      }
    }

    .flex-end {
      align-items: baseline;
      align-self: inherit;
      flex-direction: column;
      .flexbox {
        width: 100%;
        flex-direction: row;
        padding-bottom: var(--size2);
      }
    }
    .input-newsletter {
      padding: 4px;
    }
    .flexbox {
      flex-direction: column;
      input {
        width: calc(100% - var(--size1));
        /* margin-bottom: var(--size2); */
        margin-bottom: var(--size2);
      }
    }
  }
`;

export const FooterPrintStyle = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  /* background-color: antiquewhite; */
  color: var(--green);
  a {
    color: var(--green);
  }
  border-bottom: 1px solid var(--green);
  /* position: absolute;
  top: 0; */
  z-index: 60000000;
  p br {
    display: none; /* This hides the line breaks */
  }

  /* width: 100%; */
`;

export default FooterStyle;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  // Define the resources for each language
  en: {
    translation: require("./src/locales/en/translation.json"),
  },
  // Add more languages as needed
  fr: {
    translation: require("./src/locales/fr/translation.json"),
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Set the enfault language
  fallbackLng: "en", // Set the fallback language
  interpolation: {
    escapeValue: false, // React already escapes the values
  },
});

export default i18n;

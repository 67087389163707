import { useState } from "react";

export default function useSendNewsletter({ values }) {
  const [errorNews, setError] = useState();
  const [loadingNews, setLoading] = useState(false);
  const [messageSuccessNews, setMessageSuccess] = useState("");

  async function submitNewsletter(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const body = { email: values.email };

    try {
      const res = await fetch(
        `${process.env.GATSBY_SERVERLESS_BASE}/sendNews`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const result = await res.json();

      if (res.ok) {
        setLoading(false);
        setMessageSuccess(result.message);
      } else {
        setLoading(false);
        setError(result.message);
      }
    } catch (error) {
      setLoading(false);
      setError("Network error. Please try again later.");
    }
  }

  return {
    messageSuccessNews,
    errorNews,
    loadingNews,
    submitNewsletter,
  };
}
